@import "@/styles/variables.module.scss";

.carousel_navigate_fraction_main {
  width: 100%;
  // padding-bottom: 40px !important;
  // background: $primary-color-red-100;

  // .swiper-pagination-horizontal {
  //   bottom: 0px !important;
  // }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    font-size: 18px;
    // background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .swiper-pagination-fraction,
  .swiper-pagination-custom {
    color: #000000;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  & .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 0px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
    z-index: 9999;
  }
}

.nvf_swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: $secondary-color-gray-100 !important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
  margin: 0 10px !important;

  @media screen and (max-width: 768px) {
    width: 12px !important;
    height: 12px !important;
    margin: 0 5px !important;
  }
}

.nvf_swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: $primary-color-red-100 !important;
}
