$primary-color-red-100: linear-gradient(90deg, #f10503 0%, #cf0301 100%);
$primary-color-red-200: #f10503;
$primary-color-red-300: #cf0301;

$secondary-color-gray-100: #e8e8e8;

$mobile: "";
$tablet: "";
$desktop: "";

// Responsive

:export {
  primaryColorRed100: $primary-color-red-100;

  secondaryColorGray100: $secondary-color-gray-100;
}
