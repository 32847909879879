@import "@/styles/variables.module.scss";

.carousel_common_main {
  width: 100%;
  // padding-bottom: 40px !important;
  // background: $primary-color-red-100;

  .swiper-pagination-horizontal {
    bottom: 0px !important;
  }
}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: $secondary-color-gray-100 !important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
  margin: 0 10px !important;

  @media screen and (max-width: 768px) {
    width: 12px !important;
    height: 12px !important;
    margin: 0 5px !important;
  }
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: $primary-color-red-100 !important;
}
