.best-solution img, best-solution video  {
    max-width: 100% !important;
    height: auto !important;
    margin: 0 auto !important;
    
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .best-solution h1,
  .best-solution h2,
  .best-solution h3,
  .best-solution h4,
  .best-solution h5,
  .best-solution h6,
  .best-solution p,
  .best-solution span {
    font-family: var(--font-poppins), var(--font-kanit) !important;
  } 
